export const isRegistrationPlateValid = (inputString: string): boolean => {
  const sanitizedInput = inputString?.replace(/[/\s-]/g, '');

  const patterns: string[] = [
    '^[A-HJ-NP-TV-Z]{2}[0-9]{3}[A-HJ-NP-TV-Z]{2}$',
    '^[0-9]{1,3}[A-Z]{1,3}[0-9]{2}$',
    '^[0-9]{4}[A-Z]{2}[0-9]{2}$',
    '^[0-9]{1,3}[A-Z]{1,3}[0-9]{3}$',
    '^[0-9]{4}[A-Z]{2}[0-9]{3}$',
    '^[0-9]{1,3}[A-Z]{1,3}(2A|2B)$',
    '^[0-9]{4}[A-Z]{2}(2A|2B)$',
  ];

  for (const pattern of patterns) {
    if (new RegExp(pattern, 'i').test(sanitizedInput)) {
      return true;
    }
  }

  return false;
};

export const isOrderNumberValid = (inputString: string): boolean => {
  const regexPattern = /^AD-AUTO-\d{8}-\d{6}$/i;
  return regexPattern.test(inputString);
};

export const validInputPattern =
  '^(?=.*[a-zA-Z0-9\u00C0-\u017F])[a-zA-Z0-9\u00C0-\u017F ]*$';
